.App {
  text-align: center;
  color:white;
  height: 100%;
  background: linear-gradient(0deg, #1b2735 0%, #090a0f 100%);
}

.InlineLink {
  cursor: pointer;
  text-decoration:none;
  color: inherit;
}

.OverlayButton {
  font-size: 20px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding-bottom: 10px;
}

.LeftBar {
  position: fixed;
  display: list-item;
  list-style: none;
  bottom: 0;
  left: 10px;
  right: auto;
  z-index: 1;
  font-size: 10px;
}


.RightBar {
  position: fixed;
  display: list-item;
  list-style: none;
  bottom: 0;
  left: auto;
  right: 10px;
  z-index: 1;
  font-size: 15px;

  .Email {
    writing-mode: vertical-rl;
    padding-bottom: 15px;
  }
  
}

.VerticalLine {
  writing-mode: sideways-lr;
  border-right: 3px solid white;
  width: 12px;
  height: 10vh;
}


@media screen and (max-width: 530px) {
  .RightBar {
    display: none;
  }
  .LeftBar {
    display: none;
  }
}