.FooterButton {
  font-size: 30px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

@media screen and (min-width: 530px) {
  .FooterLinks {
    display: none;
  }
}