#Navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  background-color: inherit;

  button {
    border: none;
    outline: none;
    background-color: inherit;
    cursor: pointer;
    color: white;
    text-shadow: 1px 1px 10px black;
  }

  .Navbarlist {
    font-weight: bold;
    width: auto;
    overflow: hidden;
    margin: auto;
    max-width: 900px;
    height: 0;
    text-align: center;

    .NavbarButton {
      width:100%;
      font-size: 20px;
      padding: 10px 30px 10px 30px;
    }
    .NavbarButton:hover {
      background-color: gray;
    }
  }
  .shownav {
    height: 140px;
    background-color: #090a0f;
    // background-color: #efefef;
  }
  .showbutton {
    height: 140px;
    background-color: #090a0f !important;
    float: top;
  }
  #ExpandNavbarButton {
    font-size: 30px;
    color: white;
    // float: right;
    position: absolute;
    top: 0; right: 0;
    background-color: inherit;
  }
}

// shrinking
@media screen and (min-width: 530px) {
  #Navbar {

    background-color: #090a0f;
    .Navbarlist {
      height: auto;
      display: flex;
      margin: 0;
    }

    .shownav {
      height: auto;
      display: flex;
      margin: 0;
    }
    #ExpandNavbarButton {
      display: none;
    }
  }
}
